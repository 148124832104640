import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid
} from "@mui/material";
import Drawer from "../../dashboard/src/Drawer.web";
// Customizable Area End

import FormsController, {
    Props,
    configJSON,
} from "./FormsController";
import PageHeader from "../../../components/src/PageHeader";
import FolderView from "../../../components/src/FolderView";
import InsideFolder from "../../../components/src/InsideFolder";

// Customizable Area Start
const folderArray = [
    {name: "Standard Terms and Conditions – Professional Clients", fileCount: "152", color: "#FBBF24"},
    {name: "Terms and Conditions – Retail Clients", fileCount: "37", color: "#F87171"},
    {name: "Account Application Form – Individual Clients", fileCount: "16", color: "#34D399"},
    {name: "Default client portfolio finances template", fileCount: "20", color: "#FBBF24"},
    {name: "KYC and Suitability Form – All Clients", fileCount: "9", color: "#34D399"},
    {name: "NDA (Non-Disclosure Agreement) template", fileCount: "41", color: "#FBBF24"},
    {name: "Introducer Agreement - Outbound", fileCount: "8", color: "#FBBF24"},
    {name: "Third-Party Service Level Agreemen", fileCount: "213", color: "#3B82F6"}
];

const folderFiles = [
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "09 Aug 2024, 08:00 PM", lastUpdate: "09 Aug 2024, 08:00 PM", uploadBy: "Kathryn Murphy"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "08 Aug 2024, 10:45 PM", lastUpdate: "08 Aug 2024, 10:45 PM", uploadBy: "Indri Gintan"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "07 Aug 2024, 07:31 PM", lastUpdate: "07 Aug 2024, 07:31 PM", uploadBy: "Savannah Nguyen"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "04 Aug 2024, 11:00 AM", lastUpdate: "04 Aug 2024, 11:00 AM", uploadBy: "Leslie Alexander"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "06 Aug 2024, 02:19 AM", lastUpdate: "06 Aug 2024, 02:19 AM", uploadBy: "Darrell Steward"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "05 Aug 2024, 06:00 PM", lastUpdate: "05 Aug 2024, 06:00 PM", uploadBy: "Christian Hamid"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "04 Aug 2024, 09:15 AM", lastUpdate: "04 Aug 2024, 09:15 AM", uploadBy: "Chris Fanny"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "05 Aug 2024, 01:00 PM", lastUpdate: "05 Aug 2024, 01:00 PM", uploadBy: "Cameron Williamson"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "04 Aug 2024, 11:00 AM", lastUpdate: "04 Aug 2024, 11:00 AM", uploadBy: "Leslie Alexander"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "04 Aug 2024, 09:15 AM", lastUpdate: "04 Aug 2024, 09:15 AM", uploadBy: "Chris Fanny"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "06 Aug 2024, 02:19 AM", lastUpdate: "06 Aug 2024, 02:19 AM", uploadBy: "Darrell Steward"},
    {file: {name: "File name.doc", size: "128 KB"}, uploadDate: "06 Aug 2024, 02:19 AM", lastUpdate: "06 Aug 2024, 02:19 AM", uploadBy: "Darrell Steward"}
];
// Customizable Area End

export default class Forms extends FormsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { insideFolder, selectedFolderName, currentPage } = this.state;
        const folderData = {
            folderName: selectedFolderName,
            folderFiles: folderFiles,
            currentPage: currentPage,
            totalPages: 15
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <Drawer navigation={this.props.navigation} pageName = "Forms">
                <Box style={webStyle.mainBox}>
                    {
                        !insideFolder?
                        <>
                            <PageHeader heading={configJSON.Forms}/>
                            <Box style={webStyle.folderMainBox}>
                                <Typography style={webStyle.folderText}>
                                    {configJSON.Folders}
                                </Typography>
                                <Grid container style={webStyle.folderContainerGrid}>
                                    {folderArray.map((item, index) => 
                                        <Grid
                                            data-test-id={`folderGrid${index}TestId`}
                                            item 
                                            lg={3}
                                            key={index}
                                            style={webStyle.folderItemGrid}
                                            onClick={() => this.handleFolderOpen(item.name)}
                                        >
                                            <FolderView
                                                folderName={item.name}
                                                fileCount={item.fileCount}
                                                iconColor={item.color}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </>:
                        <InsideFolder
                            data-test-id="insideFolderTestId"
                            folderData = {folderData}
                            backClick={this.handleFolderClose}
                            handlePageChange={(page)=> this.handlePageChange(page)}
                        />
                    }
                </Box>
            </Drawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainBox: {
        background: "#fafaf9",
        padding: "24px",
        height: "100%"
    },
    folderContainerGrid: {
        marginTop: "20px",
        justifyContent: "space-between",
        gridRowGap: "25px"
    },
    folderItemGrid: {
        maxWidth: "25%",
        flexBasis: "23.5%"
    },
    folderMainBox: {
        marginTop: "25px"
    },
    folderText: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600
    }
};
// Customizable Area End
