import React, { useState } from 'react';

import { Box, Typography, Modal, Button, Dialog, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Headers = () => {
  const [open, setOpen] = useState(false);
  const [openReset, setResetOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleTogglePasswordVisibility = (setVisibility: (arg0: (prev: any) => boolean) => void) => {
    setVisibility((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleResetPassword = () => {
    setOpen(false)
    setResetOpen(true)
  }
  const handleCloseResetPassword = () => {
    setResetOpen(false)
  }


  const style = {
    position: 'absolute',
    top: '22%',
    left: '90%',
    transform: 'translate(-50%, -50%)',
    width: '340px',
    height: '185px',
    borderRadius: '16px',
    bgcolor: 'background.paper',
    boxShadow: 24,

  };

  return (
    <Box style={webStyle.mainBox}>
      <Box padding={'20px'} style={{
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '14px',
        width: '100%'
      }}>

        <Box onClick={handleOpen} style={{ width: '208px', height: '52px', border: '2px solid rgb(226, 232, 240)', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <Box >
            <Typography variant='body1'>
              Kristian Watson
            </Typography>

            <Typography variant='body2' color={'#64748B'}>
              Sales
            </Typography>
          </Box>
          <Box style={{ marginLeft: '35px' }}

          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 6L8 10L12 6" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>



          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box >
            <Box style={{ width: '305px', height: '45px', paddingLeft: "2rem", borderBottom: '1px solid #CBD5E1', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography style={{ lineHeight: '22px', fontWeight: 600, fontSize: "14px", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                Account
              </Typography>
            </Box>
            <Box style={{ height: '70%', padding: '1rem' }}>
              <Box
                sx={{
                  width: '304px',
                  height: '52px',
                  borderRadius: '8px',
                  paddingLeft: '10px',
                  marginBottom: '3px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    border: '1px solid #CBD5E1',
                    backgroundColor: '#F8FAFC'
                  },
                }}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_21883_28208)">
                    <path d="M1.66669 13.3333C1.66669 10.9762 1.66669 9.79772 2.39892 9.06549C3.13115 8.33325 4.30966 8.33325 6.66669 8.33325H13.3334C15.6904 8.33325 16.8689 8.33325 17.6011 9.06549C18.3334 9.79772 18.3334 10.9762 18.3334 13.3333C18.3334 15.6903 18.3334 16.8688 17.6011 17.601C16.8689 18.3333 15.6904 18.3333 13.3334 18.3333H6.66669C4.30966 18.3333 3.13115 18.3333 2.39892 17.601C1.66669 16.8688 1.66669 15.6903 1.66669 13.3333Z" stroke="#0F172A" stroke-width="1.5" />
                    <path d="M10 11.6667V15.0001" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M5 8.33341V6.66675C5 3.90532 7.23858 1.66675 10 1.66675C12.7614 1.66675 15 3.90532 15 6.66675V8.33341" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_21883_28208">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>


                <Typography style={{ margin: '10px', fontWeight: 600, fontSize: "14px", }} onClick={handleResetPassword}>
                  Reset Password
                </Typography>
              </Box>

              <Box sx={{
                width: '304px',
                height: '46px',
                borderRadius: '8px',
                paddingLeft: '10px',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                  border: '1px solid #CBD5E1',
                  backgroundColor: '#F8FAFC'
                },
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.16667 0.5H13.8333C14.75 0.5 15.5 1.25 15.5 2.16667V13.8333C15.5 14.75 14.75 15.5 13.8333 15.5H2.16667C1.25 15.5 0.5 14.75 0.5 13.8333V11.3333C0.5 10.875 0.875 10.5 1.33333 10.5C1.79167 10.5 2.16667 10.875 2.16667 11.3333V13C2.16667 13.4583 2.54167 13.8333 3 13.8333H13C13.4583 13.8333 13.8333 13.4583 13.8333 13V3C13.8333 2.54167 13.4583 2.16667 13 2.16667H3C2.54167 2.16667 2.16667 2.54167 2.16667 3V4.66667C2.16667 5.125 1.79167 5.5 1.33333 5.5C0.875 5.5 0.5 5.125 0.5 4.66667V2.16667C0.5 1.25 1.24167 0.5 2.16667 0.5ZM11.1583 8.58333L8.16667 11.575C7.84167 11.9 7.31667 11.9 6.99167 11.575C6.675 11.2583 6.66667 10.725 6.99167 10.4L8.55833 8.83333H1.33333C0.875 8.83333 0.5 8.45833 0.5 8C0.5 7.54167 0.875 7.16667 1.33333 7.16667H8.55833L6.99167 5.59167C6.66667 5.26667 6.66667 4.74167 6.99167 4.41667C7.14736 4.26062 7.35874 4.17293 7.57917 4.17293C7.7996 4.17293 8.01097 4.26062 8.16667 4.41667L11.1583 7.40833C11.4833 7.73333 11.4833 8.25833 11.1583 8.58333Z" fill="#DC2626" />
                </svg>

                <Typography style={{ margin: '10px', color: '#DC2626', fontWeight: 600, fontSize: "14px" }}>
                  Log Out
                </Typography>
              </Box>
            </Box>
          </Box>


        </Box>
      </Modal>
      <Dialog
        open={openReset}
        onClose={handleCloseResetPassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ width: '480px', height: '366px', bgcolor: 'background.paper', borderRadius: '16px' }}>
          <Box style={{ width: '455px', height: '60px', borderBottom: '1px solid #E2E8F0', display: 'flex', alignItems: 'center', padding: '12px', justifyContent: 'space-between' }}>
            <Typography style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: '600', fontSize: '18px' }}>Reset Password</Typography>
            <Box style={{ paddingRight: '10px' }} onClick={handleCloseResetPassword} >
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L1 9M1 1L9 9" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </Box>

          </Box>

          <Box display={'flex'} flexDirection={"column"} style={{ width: '450px', height: '208px', borderBottom: '1px solid #E2E8F0', marginLeft: '1rem' }}>

            <Box my={'30px'} >
              <Typography style={{
                fontSize: "12px", fontWeight: '600', color: '#475569'
              }}>Current Password</Typography>
              <TextField
                id="outlined-password-input"
                type={showCurrentPassword ? "text" : "password"}
                autoComplete="current-password"
                placeholder="Enter Your Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                data-testid='handlePasswordChange'
                style={{ width: '100%' }}
                inputProps={{ style: { paddingTop: '21px' } }}
                InputProps={{
                  style: { borderRadius: '50px', height: '35px' },
                  startAdornment: (
                    <InputAdornment position="start">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16Z" stroke="#94A3B8" stroke-width="1.5" />
                        <path d="M12 14V18" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M6 10V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => handleTogglePasswordVisibility(setShowCurrentPassword)}
                      >
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: "12px", fontWeight: '600', color: '#475569' }}>New Password</Typography>
              <TextField
                id="outlined-password-input"
                type={showNewPassword ? "text" : "password"}
                autoComplete="current-password"
                placeholder="Enter Your Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                data-testid='handlePasswordChange'
                style={{ width: '100%' }}
                inputProps={{ style: { paddingTop: '21px' } }}
                InputProps={{
                  style: { borderRadius: '50px', height: '35px' },
                  startAdornment: (
                    <InputAdornment position="start">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.755 14.2549H14.965L14.685 13.9849C15.665 12.8449 16.255 11.3649 16.255 9.75488C16.255 6.16488 13.345 3.25488 9.755 3.25488C6.165 3.25488 3.255 6.16488 3.255 9.75488C3.255 13.3449 6.165 16.2549 9.755 16.2549C11.365 16.2549 12.845 15.6649 13.985 14.6849L14.255 14.9649V15.7549L19.255 20.7449L20.745 19.2549L15.755 14.2549ZM9.755 14.2549C7.26501 14.2549 5.255 12.2449 5.255 9.75488C5.255 7.26488 7.26501 5.25488 9.755 5.25488C12.245 5.25488 14.255 7.26488 14.255 9.75488C14.255 12.2449 12.245 14.2549 9.755 14.2549Z" fill="#94A3B8" />
                      </svg>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => handleTogglePasswordVisibility(setShowNewPassword)}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '70px', paddingRight: "10px" }}>
            <Button style={{ color: '#6366F1', backgroundColor: '#EEF2FF', borderRadius: '100px' }} size="large" onClick={handleCloseResetPassword}>
              Cancel
            </Button>
            <Button variant="contained" style={{ backgroundColor: '#6366F1', borderRadius: '100px' }} size="large">
              Save Changes
            </Button>
          </Box>
        </Box>
      </Dialog>
      <style>
        {
          `.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
            top:80px !important
          }`
        }
      </style>
    </Box >
  )
}

export default Headers

const webStyle = {
  mainBox: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0"
  }
};

