import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Table, TableContainer, TableHead, TableRow, TableCell, Checkbox, TableBody, Avatar, List, ListItem, FormControlLabel, Dialog, styled,  } from "@mui/material";
import { check, file, files, home, pdf, people, star, uncheck } from './assets'
import ReactQuill from 'react-quill';
import Drawer from "./Drawer.web";
import "./Dashboard.css"
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  showBox = () => {
    return (
      <Box mx={'24px'} my={"32px"} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ClientNotification >
          <Box className="clientNotify" >
            <Typography  style={{ fontWeight: 'bold' }} variant="h6">Client Notification</Typography>
            <Button  style={{ backgroundColor: '#6366F1' }} size="medium" variant="contained">See All</Button>
          </Box>
          <List>
            <ListItem
              style={{
                display: "flex",
                alignItems: "center",
                height: "488px",
                flexDirection: "column",
                overflowY: "auto"
              }}
            >
              {this.state.notificationsData.map((item: any, index: number) => (
                <Box key={index}
                data-test-id="notification-box"
                  style={{
                    border: "1px solid #E5E5E5",
                    borderRadius: "8px",
                    display: "flex",
                    transition: "background-color 0.3s ease",
                    justifyContent: "space-between",
                    cursor: "pointer"
                  }}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f5f5f5")}
                >
                  <Box style={{ margin: "15px" }}>
                    {item.icon}
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "column",justifyContent: "space-between" }}>
                    <Typography
                      style={{
                        marginRight: "15px",
                        fontWeight: "600",
                        justifyContent: "space-between",
                        display: "flex",
                        marginTop: "10px"
                      }}
                    >
                      <Box>{item.title}</Box>
                      <Box
                        style={{
                          height: "7px",
                          width: "7px",
                          borderRadius: "50%",
                          backgroundColor: "red"
                        }}
                      ></Box>
                    </Typography>
                    <Typography variant="body2" style={{ color: "#64748B" }}>
                      {item.message}
                    </Typography>
                    <Typography variant="body2" style={{ color: "#64748B" }}>
                      {item.timeAgo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </ListItem>
          </List>

        </ClientNotification>

        <Notification style={{ width: '370px', height: '600px', border: '2px solid #E2E8F0', borderRadius: '16px', margin: '10px 16px' }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Notification</Typography>
            <Button variant="contained" style={{ backgroundColor: '#6366F1' }} size="medium">See All</Button>
          </Box>
          <List>
            <ListItem
              style={{
                alignItems: "center",
                display: "flex",
                height: "488px",
                flexDirection: "column",
                overflowY: "auto"
              }}
            >
              {this.state.notificationsData.map((item: any, index: number) => (
                <Box
                data-test-id="notification-box2"
                  key={index}
                  style={{
                    border: "1px solid #E5E5E5",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                    display: "flex",
                    transition: "background-color 0.3s ease",
                    cursor: "pointer"
                  }}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f5f5f5")}
                >
                  <Box style={{ margin: "15px" }}>{item.icon}</Box>
                  <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Typography
                      style={{
                        fontWeight: "600",
                        justifyContent: "space-between",
                        marginRight: "15px",
                        display: "flex",
                        marginTop: "10px"
                      }}
                    >
                      <Box>{item.title}</Box>
                      <Box
                        style={{
                          height: "7px",
                          borderRadius: "50%",
                          width: "7px",
                          backgroundColor: "red"
                        }}
                      ></Box>
                    </Typography>
                    <Typography  
                    style={{ color: "#64748B" }} 
                    variant="body2">
                      {item.message}
                    </Typography>
                    <Typography  style={{ color: "#64748B" }} variant="body2">
                      {item.timeAgo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </ListItem>

          </List>
        </Notification>

        <Box style={{ width: '370px', height: '600px', border: '2px solid #E2E8F0', borderRadius: '16px', margin: '10px 16px' }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '12px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Notes</Typography>
            <Button variant="contained" style={{ backgroundColor: '#6366F1' }} size="large" data-test-id="notes" onClick={this.handleNotes} >+Add Notes</Button>
            <Dialog data-test-id="dialog" PaperProps={{ style: { borderRadius: "16px" } }} open={this.state.isOpen} onClose={this.handleClose}>
              <Box>
                <StyledQuill data-test-id="quill" value={this.state.value} onChange={this.handleChange} />
              </Box>
            </Dialog>
          </Box>
          <Box >
            <Box
              sx={{
                height: '425px',
                padding: 4,
                backgroundColor: '#FFF3CD',
                borderRadius: '10px',
                border: '1px solid #D1E7DD',
                color: '#6C757D',
                mx: "16px",
                my: '5px'

              }}
            >
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#000' }}>
                Lorem ipsum dolor sit amet.
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: 2 }}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna.
              </Typography>
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel control={<Checkbox />} label="Watch" />
                <FormControlLabel control={<Checkbox />} label="New bike helmet" />
                <FormControlLabel control={<Checkbox />} label="Jacket" />
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  ...
                </Typography>
              </Box>
            </Box>
          </Box>

        </Box>

      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Drawer navigation={this.props.navigation} pageName = "Dashboard">
        <Box style={{ display: 'flex', width: '100%' }}>
          <Box style={{ height: '65%' }}>
            <Box mx={'40px'}>

              <Box style={{ border: '2px solid #E2E8F0', borderRadius: '16px', marginTop: '24px', height: '469px', padding: '12px' }}>

                <Box style={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px'
                }}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Client updates</Typography>
                  <Button variant="contained" style={{ backgroundColor: '#6366F1' }} size="medium">See All</Button>
                </Box>
                <TableContainer style={{ width: '100%', height: '78%' }}>
                  <Table style={{ border: '2px solid #E2E8F0', borderRadius: '12px', width: '100%', height: '100%', overflowY: "auto" }}>
                    <TableHead>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" >
                              <FormControlLabel
                                label=""
                                control={<Checkbox icon={<img src={uncheck.default} data-testid="toggleCheckbox" onClick={this.toggleCheckbox} />}
                                  checkedIcon={<img src={check.default} onClick={this.toggleCheckbox} data-testid='toggleCheckbox' />}
                                  checked={this.state.isCheckedAll}
                                  onChange={this.toggleMasterCheckbox} />}
                              />
                            </TableCell>
                            <TableCell style={{ borderRight: '1px solid #E2E8F0' }}>Client</TableCell>
                            <TableCell style={{ borderRight: '1px solid #E2E8F0' }}>Detail</TableCell>
                            <TableCell style={{ borderRight: '1px solid #E2E8F0' }}>Updated</TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.tableData.map((item: any, index: any) => (
                            <TableRow>
                              <TableCell align="right">
                                <FormControlLabel
                                  label=""
                                  data-test-id="toggleCheckbox"
                                  control={<Checkbox icon={<img src={uncheck.default} data-testid="toggleCheckbox" onClick={this.toggleCheckbox} />}
                                    checkedIcon={<img src={check.default} onClick={this.toggleCheckbox} data-testid='toggleCheckbox' />}
                                    checked={item.isChecked}
                                    onChange={() => this.toggleCheckbox(index)} />}
                                />
                              </TableCell>
                              <TableCell style={{ display: 'flex', alignItems: 'center', borderRight: '1px solid #E2E8F0' }}>
                                <Avatar src={item.avatar} alt={item.name} style={{ marginRight: '8px', backgroundColor: '#8000FF', color: '#FFFFFF' }}>
                                  {item.name[0]}
                                </Avatar>
                                {item.name}
                              </TableCell>
                              <TableCell style={{ borderRight: '1px solid #E2E8F0' }}>{item.detail}</TableCell>
                              <TableCell style={{ borderRight: '1px solid #E2E8F0' }}>{item.updated}</TableCell>
                              <TableCell align="left">
                                <Typography variant="body2" style={{ color: '#6366F1', cursor: 'pointer' }}>View</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box>

            </Box>

            {this.showBox()}

          </Box>
        </Box>
      </Drawer>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledQuill = styled(ReactQuill)({
  "& .ql-container": {
    height: "240px",
    border: "none"
  }
});

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { 
    fontSize: "0.8rem",
    textTransform: "capitalize" 
  }
};

const ClientNotification=styled(Box)({
  width: '370px', 
  height: '600px', 
  border: '2px solid #E2E8F0', 
  borderRadius: '16px',
  margin: '10px 16px' ,
  "& .clientNotify":{
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px'
  }
})

const Notification = styled((Box))({
  height: '600px',
  border: '2px solid #E2E8F0',
  borderRadius: '16px',
  margin: '10px 16px',
  width: '450px',
  "& .notification":{
    justifyContent: 'space-between',
    display: 'flex',
    padding: '10px'
  }
})
// Customizable Area End
