// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import Notifications from "../../blocks/notifications/src/Notifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PtCoursesScreen from "../../blocks/performancetracker/src/PtCoursesScreen";
import PtTestsForCourseScreen from "../../blocks/performancetracker/src/PtTestsForCourseScreen";
import PtStudentsOnCourseScreen from "../../blocks/performancetracker/src/PtStudentsOnCourseScreen";
import PtTestScoresForStudentScreen from "../../blocks/performancetracker/src/PtTestScoresForStudentScreen";
import PtBasicUserCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserCoursesScreen";
import PtBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserAllCoursesScreen";
import PtStudentRankingScreen from "../../blocks/performancetracker/src/PtStudentRankingScreen";
import PtStudentInfoScreen from "../../blocks/performancetracker/src/PtStudentInfoScreen";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import Catalogue  from "../../blocks/catalogue/src/Catalogue.web";
import Forms  from "../../blocks/multipageforms/src/Forms.web";
import Products  from "../../blocks/multipageforms/src/Products.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import NewPasswordWeb from "../../blocks/forgot-password/src/NewPassword.web";



const routeMap = {
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Dashboard:{
  component:Dashboard,
path:"/Dashboard"
},
Forms:{
  component:Forms,
  path:"/Forms"
},
Products:{
  component:Products,
  path:"/Products"
},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Dashboard:{
  component:Dashboard,
 path:"/Dashboard"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PtCoursesScreen:{
 component:PtCoursesScreen,
path:"/PtCoursesScreen"},
PtTestsForCourseScreen:{
 component:PtTestsForCourseScreen,
path:"/PtTestsForCourseScreen"},
PtStudentsOnCourseScreen:{
 component:PtStudentsOnCourseScreen,
path:"/PtStudentsOnCourseScreen"},
PtTestScoresForStudentScreen:{
 component:PtTestScoresForStudentScreen,
path:"/PtTestScoresForStudentScreen"},
PtBasicUserCoursesScreen:{
 component:PtBasicUserCoursesScreen,
path:"/PtBasicUserCoursesScreen"},
PtBasicUserAllCoursesScreen:{
 component:PtBasicUserAllCoursesScreen,
path:"/PtBasicUserAllCoursesScreen"},
PtStudentRankingScreen:{
 component:PtStudentRankingScreen,
path:"/PtStudentRankingScreen"},
PtStudentInfoScreen:{
 component:PtStudentInfoScreen,
path:"/PtStudentInfoScreen"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  Dashboard:{
    component:Dashboard,
    path:"/Dashboard"
  },
  ForgotPasswordWeb:{
      component:ForgotPasswordWeb,
    path:"/ForgotPassword"
  },
  NewPassword:{
    component:NewPasswordWeb,
   path:"/NewPassword"},
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;