import React, { Component } from "react";
import {
    Box,
    Typography,
    Button,
    styled
} from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

interface Props {
    heading: string
};

interface S {
};

export default class PageHeader extends Component<Props, S> {

    constructor(props: Props){
        super(props);
    };

    render() {
        return (
            <Box style={webStyle.headerBox}>
                <Typography style={webStyle.headingText}>
                    {this.props.heading}
                </Typography>
                <Box style={webStyle.buttonBox}>
                    <AddButton style={webStyle.buttonStyle}>
                        <Box style={webStyle.buttonInnerBox}>
                            <AddRoundedIcon style={{color: "#4F46E5", ...webStyle.buttonIcon}}/>
                            <Typography style={webStyle.buttonText}>Add new folder</Typography>
                        </Box>
                    </AddButton>
                    <UploadButton style={webStyle.buttonStyle}>
                        <Box style={webStyle.buttonInnerBox}>
                            <LogoutRoundedIcon style={{ color: "#FFFFFF", transform: "rotate(-90deg)", ...webStyle.buttonIcon }}/>
                        <Typography style={webStyle.buttonText}>Upload</Typography>
                        </Box>
                    </UploadButton>
                </Box>
            </Box>
        );
    }
};

const AddButton = styled(Button)({
    background: "#E0E7FF",
    color: "red",
    "&:hover": {
        background: "#E0E7FF"  
    },
    "& .MuiTypography-root": {
        fontWeight: 600,
        marginLeft: "10px",
        color: "#4F46E5"
    }
});

const UploadButton = styled(Button)({
    background: "#6366F1",
    "&:hover": {
        background: "#6366F1"  
    },
    "& .MuiTypography-root": {
        fontWeight: 600,
        marginLeft: "10px",
        color: "#FFFFFF"
    }
});

const webStyle = {
    headerBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    buttonBox: {
        display: "flex",
        gap: "15px"
    },
    headingText:{
        fontSize: "24px",
        fontWeight: 600,
        color: "#0F172A"
    },
    buttonText: {
        paddingTop: "5px"
    },
    buttonStyle: {
        height: "44px",
        textTransform: "none",
        color: "#4F46E5",
        borderRadius: "100px",
        padding: "0 16px"
    } as React.CSSProperties,
    buttonInnerBox: {
        display: "flex",
        alignItems: "center"
    },
    buttonIcon: {
        fontSize: "26px",
        fontWeight: 600
    }
};