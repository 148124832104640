import React from "react";

// Customizable Area Start
import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
import { logo, mail, password, check, uncheck } from "./assets";
import './EmailAccountLoginBlock.css'

// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleRenderPassword = () => {
    return !this.state.showPassword ? <VisibilityOutlinedIcon onClick={this.togglePasswordVisibility} data-testid='togglePasswordVisibility' style={{ cursor: 'pointer' }} /> : <VisibilityOffOutlinedIcon style={{ cursor: 'pointer' }} onClick={this.togglePasswordVisibility}/>
  };

  renderTextField = (label: string, value: string, placeholder: string, testId: string, handleChange: (event: React.ChangeEvent<HTMLInputElement>)=>void, error: string) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
        <Typography style={{ fontSize: "10px", fontWeight: 600, color: "#475569" }}>{label}</Typography>
        <TextField
          required
          type={(label === "Email" || this.state.showPassword) ? "text" : "password"}
          id="outlined-required"
          defaultValue=""
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          data-testid={testId}
          style={{ width: '100%' }}
          inputProps={{ style: { paddingTop: '21px' } }}
          InputProps={{
            style: { borderRadius: '50px', height: '35px' },
            startAdornment: (
              <InputAdornment position="start">
                <img src={label === "Email"? mail: password} />
              </InputAdornment>
            ),
            endAdornment: ( label === "Password" &&
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {this.handleRenderPassword()}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: (this.state.loginClicked && error) ? "#F87171" :"#CBD5E1"
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: (this.state.loginClicked && error) ? "#F87171" :"#CBD5E1"
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: (this.state.loginClicked && error) ? "#F87171" :"#CBD5E1"
              }
            }
          }}
        />
        {
          (this.state.loginClicked && error) && 
          <Typography style={webStyles.errorText}>
            {error}
          </Typography>
        }
      </Box>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Box style={{ display: "flex", width: "100%", height: "100%", justifyContent: 'center', alignItems: 'center' }}>
          <Box style={ webStyles.centerBox}>
            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '24px', marginTop: '30px' }}>
              <img src={logo} alt="Wealth Fusion Logo" style={{ height: '77px', width: '208px' }} />
            </Box>
            <Box style={{ display: "flex", justifyContent: 'center' }}>
              <Typography style={{ fontWeight: '400', fontSize: '18px' }}>Let’s log in to your account</Typography>
            </Box>

            <Snackbar
              open={this.state.errorLogin}
              onClose={this.handleCloseError}
              className="snackBarLogin"
              autoHideDuration={2000}
            >
              <Alert
                onClose={this.handleCloseError}
                icon={false}
                severity="error"
                sx={{ width: '100%',fontWeight:400,fontSize:'18px' }}
                className="alertChild"
              >
                {this.state.errorMessage}
              </Alert>
            </Snackbar>

            <Box display={'flex'} flexDirection={"column"} mx={"25px"} my={"20px"}>
              {this.renderTextField("Email", this.state.email, "Enter Your Email", "handleEmailChange", this.handleEmailChange, this.state.emailError)}
              {this.renderTextField("Password", this.state.password, "Enter Your Password", "handlePasswordChange", this.handlePasswordChange, this.state.passError)}
            </Box>


            <Box mx={'25px'} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
              <FormControlLabel
                control={<Checkbox
                  icon={<img src={uncheck} data-testid="toggleCheckbox" onClick={this.toggleCheckbox} />}
                  checkedIcon={<img src={check} onClick={this.toggleCheckbox} data-testid='toggleCheckbox' />}
                  checked={this.state.isChecked}
                  onChange={this.toggleCheckbox}
                />}
                label={
                  <Typography variant="body2" color="textSecondary" style={{ marginTop: '2px' }}>
                    Keep me logged in
                  </Typography>
                }
              />
              <Typography data-testid="redirectToForgotPassword" onClick={this.redirectToForgotPassword} variant="body2" color="blue" style={{ marginTop: '10px', cursor: 'pointer' }}>
                Forgot password?
              </Typography>
            </Box>

            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button data-testid="redirectToDashboard" onClick={this.handleLogin} style={{ width: '90%', borderRadius: '30px', fontSize: '16px', fontWeight: '600', textTransform: 'capitalize', cursor: 'pointer', color: this.state.email && this.state.password ? 'White' : '#64748B', backgroundColor: this.state.email && this.state.password ? 'blue' : '#E2E8F0', }} disabled={!(this.state.email && this.state.password)} >Log In</Button>
            </Box>

          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }

}

// Customizable Area Start

const webStyles = {
  centerBox: {
    width: "458px",
    border: '3px solid #E2E8F0',
    borderRadius: '40px',
    paddingBottom: "30px"
  },
  acceptButton: {
    color: 'red'
  },
  errorText: {
    color: "#DC2626",
    fontFamily: "Inter Tight",
    fontSize: "12px",
    marginTop: "5px"
  }
};

// Customizable Area End