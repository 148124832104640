import React, { Component } from "react";
import {
    Box,
    Typography
} from "@mui/material";
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';

interface Props {
    name: string;
    size: string;
};

interface S {
};

export default class FileView extends Component<Props, S> {

    constructor(props: Props){
        super(props);
    };

    render() {
        const { name, size } = this.props;
        return (
            <Box style={webStyle.mainBox}>
                <Box style={webStyle.noteIconBox}>
                    <NotesRoundedIcon/>
                </Box>
                <Box style={webStyle.nameSizeBox}>
                    <Typography style={webStyle.nameText}>
                        {name}
                    </Typography>
                    <Typography style={webStyle.sizeText}>
                        {size}
                    </Typography>
                </Box>
            </Box>
        );
    }
};

const webStyle = {
    mainBox: {
        display: "flex",
        alignItems: "center",
        gap: "15px"
    },
    noteIconBox: {
        width: "42px",
        height: "42px",
        background: "#F1F5F9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "18px",
        color: "#64748B",
        borderRadius: "6px"
    },
    nameSizeBox: {
        display: "flex",
        flexDirection: "column"
    } as React.CSSProperties,
    nameText: {
        fontSize: "12px",
        color: "#0F172A"
    },
    sizeText: {
        fontSize: "12px",
        color: "#64748B"
    }
};