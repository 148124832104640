import React, { Component, createRef } from "react";
import {
    Box,
    Typography
} from "@mui/material";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

interface Props {
    folderName: string;
    fileCount: string;
    iconColor: string;
};

interface S {
    isEllipsis: boolean;
};

export default class FolderView extends Component<Props, S> {

    textRef = createRef<HTMLDivElement>();

    constructor(props: Props){
        super(props);
        this.state = {
            isEllipsis: false
        };
    };

    componentDidMount() {
        this.checkOverflow();
        window.addEventListener('resize', this.checkOverflow);
    };
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOverflow);
    };

    checkOverflow = () => {
        if (this.textRef.current) {
          const { scrollHeight, clientHeight } = this.textRef.current;
          this.setState({ isEllipsis: scrollHeight > clientHeight });
        }
    };

    render() {
        const { folderName, fileCount, iconColor } = this.props;
        return (
            <Box style={webStyle.mainBox}>
                <Box style={webStyle.iconBox}>
                    <FolderOutlinedIcon style={{...webStyle.folderIcon, color: iconColor}}/>
                    <MoreHorizOutlinedIcon style={webStyle.menuIcon}/>
                </Box>
                <Box style={webStyle.divBox}>
                    <div ref={this.textRef} style={webStyle.folderName}>
                        <Typography 
                            variant="body2"
                            component="span" 
                            style={webStyle.folNameText}
                        >
                            {folderName}
                        </Typography>
                    </div>
                </Box>
                <Typography style={webStyle.fileCount}>
                    {fileCount} files
                </Typography>
            </Box>
        );
    }
};

const webStyle = {
    mainBox: {
        border: "1px solid #E2E8F0",
        background: "#FFFFFF",
        padding: "20px",
        borderRadius: "12px",
        display: "flex",
        gap: "10px",
        flexDirection: "column"
    } as React.CSSProperties,
    iconBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px"
    },
    folderIcon: {
        fontSize: "40px"
    },
    menuIcon: {
        color: "#64748B"
    },
    folderName: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        textOverflow: 'ellipsis',
        lineHeight: '1.5rem'
    } as React.CSSProperties,
    fileCount: {
        fontSize: "12px",
        color: "#3B82F6"
    },
    divBox: {
        display: "flex",
        alignItems: "center",
        height: "45px"
    },
    folNameText: {
        fontSize: "16px",
        color: "#334155",
        fontWeight: 600
    }
};