import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { mail,card,filess } from "./assets";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  value:string;
  isOpen:boolean;
  loading: boolean;
  isChecked : boolean;
  isCheckedAll: boolean, 
  tableData: any[];
  notificationsData  : any[];
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      value:"",
      token: "",
      loading: false,
      isChecked: false,
      isCheckedAll: false, 
      isOpen:false,
      tableData: [
        { id: 1, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '09 Aug 2024, 08:00 PM', isChecked:false },
        { id: 2, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '08 Aug 2024, 10:45 PM', isChecked:false },
        { id: 3, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipdolor sum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '07 Aug 2024, 07:31 PM' , isChecked:false},
        { id: 4, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '06 Aug 2024, 02:19 AM' , isChecked:false},
        { id: 5, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '05 Aug 2024, 06:00 PM' , isChecked:false},
        { id: 6, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '05 Aug 2024, 01:00 PM', isChecked:false },
        { id: 7, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '05 Aug 2024, 01:00 PM', isChecked:false },
        { id: 8, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '05 Aug 2024, 01:00 PM', isChecked:false },
        { id: 9, name: 'Lorem ipsum.', avatar: '', detail: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.', updated: '05 Aug 2024, 01:00 PM', isChecked:false },
      ],
      notificationsData : [
        {
          id: 1,
          title: "Lorem ipsum dolor.",
          message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut.Lorem ipsum dolor sit amet",
          timeAgo: "5h ago",
          icon: mail,
          isUnread: true, 
        },
        {
          id: 2,
          title: "Lorem ipsum dolor.",
          message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut.Lorem ipsum dolor sit amet",
          timeAgo: "5h ago",
          icon: card,
          isUnread: true,
        },
        {
          id: 3,
          title: "Lorem ipsum dolor.",
          message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut.Lorem ipsum dolor sit amet",
          timeAgo: "5h ago",
          icon: filess,
          isUnread: false,
        },
        {
          id: 4,
          title: "Lorem ipsum dolor.",
          message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut.Lorem ipsum dolor sit amet",
          timeAgo: "5h ago",
          icon: filess,
          isUnread: false,
        },
        {
          id: 5,
          title: "Lorem ipsum dolor.",
          message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut.Lorem ipsum dolor sit amet",
          timeAgo: "5h ago",
          icon: filess,
          isUnread: false,
        },
        {
          id: 6,
          title: "Lorem ipsum dolor.",
          message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut.Lorem ipsum dolor sit amet",
          timeAgo: "5h ago",
          icon: mail,
          isUnread: false,
        },
        {
          id: 7,
          title: "Lorem ipsum dolor.",
          message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut.Lorem ipsum dolor sit amet",
          timeAgo: "5h ago",
          icon: card,
          isUnread: false,
        },
      ],
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  toggleCheckbox = (index:any) => {
    this.setState(prevState => {
      const updatedTableData = [...prevState.tableData];
      updatedTableData[index].isChecked = !updatedTableData[index].isChecked;
      return { tableData: updatedTableData };
    });
  };

  toggleMasterCheckbox = () => {
    this.setState(prevState => {
      const newIsCheckedAll = !prevState.isCheckedAll;
      const updatedTableData = prevState.tableData.map(item => ({
        ...item,
        isChecked: newIsCheckedAll
      }));
      return {
        isCheckedAll: newIsCheckedAll, 
        tableData: updatedTableData 
      };
    });
  };

  handleNotes = () => {
    this.setState({isOpen:true})
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleChange = (value: any) => {
    this.setState({ value });
  };

  
  
  // Customizable Area End
}
