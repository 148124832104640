import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import Drawer from "../../dashboard/src/Drawer.web";
// Customizable Area End

import ProductsController, {
    Props,
    configJSON,
} from "./ProductsController";
import InsideFolder from "../../../components/src/InsideFolder";
import FolderView from "../../../components/src/FolderView";
import PageHeader from "../../../components/src/PageHeader";

// Customizable Area Start
const folders = [
    {name: "Mood of the Month", fileCount: "152", color: "#FBBF24"},
    {name: "Mood of the Week", fileCount: "37", color: "#F87171"},
    {name: "ETF List", fileCount: "16", color: "#34D399"},
    {name: "Bond List", fileCount: "20", color: "#FBBF24"},
    {name: "Pitchbook", fileCount: "9", color: "#34D399"},
    {name: "High Yield/Dividend List", fileCount: "41", color: "#FBBF24"},
    {name: "Model Portfolio", fileCount: "8", color: "#3B82F6"}
];

const openFolderFiles = [
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "09 Aug 2024, 08:00 PM", uploadDate: "09 Aug 2024, 08:00 PM", uploadBy: "Kathryn Murphy"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "08 Aug 2024, 10:45 PM", uploadDate: "08 Aug 2024, 10:45 PM", uploadBy: "Indri Gintan"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "07 Aug 2024, 07:31 PM", uploadDate: "07 Aug 2024, 07:31 PM", uploadBy: "Savannah Nguyen"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "04 Aug 2024, 11:00 AM", uploadDate: "04 Aug 2024, 11:00 AM", uploadBy: "Leslie Alexander"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "06 Aug 2024, 02:19 AM", uploadDate: "06 Aug 2024, 02:19 AM", uploadBy: "Darrell Steward"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "05 Aug 2024, 06:00 PM", uploadDate: "05 Aug 2024, 06:00 PM", uploadBy: "Christian Hamid"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "04 Aug 2024, 09:15 AM", uploadDate: "04 Aug 2024, 09:15 AM", uploadBy: "Chris Fanny"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "05 Aug 2024, 01:00 PM", uploadDate: "05 Aug 2024, 01:00 PM", uploadBy: "Cameron Williamson"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "04 Aug 2024, 11:00 AM", uploadDate: "04 Aug 2024, 11:00 AM", uploadBy: "Leslie Alexander"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "04 Aug 2024, 09:15 AM", uploadDate: "04 Aug 2024, 09:15 AM", uploadBy: "Chris Fanny"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "06 Aug 2024, 02:19 AM", uploadDate: "06 Aug 2024, 02:19 AM", uploadBy: "Darrell Steward"},
    {file: {name: "File name.doc", size: "129 KB"}, lastUpdate: "06 Aug 2024, 02:19 AM", uploadDate: "06 Aug 2024, 02:19 AM", uploadBy: "Darrell Steward"}
];
// Customizable Area End

export default class Products extends ProductsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { openFolder, inFolder, selectedPage } = this.state;
        const folderData = {
            folderName: inFolder,
            folderFiles: openFolderFiles,
            currentPage: selectedPage,
            totalPages: 15
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <Drawer navigation={this.props.navigation} pageName = "Products">
                <Box style={webStyle.mainBoxStyle}>
                    {
                        !openFolder?
                        <>
                            <PageHeader heading={configJSON.Products}/>
                            <Box style={webStyle.folderMainBox}>
                                <Typography style={webStyle.folderText}>
                                    {configJSON.Folders}
                                </Typography>
                                <Grid container style={webStyle.folderContainerGrid}>
                                    {folders.map((value, index) => 
                                        <Grid
                                            key={index}
                                            data-test-id={`folderGrid${index}TestId`}
                                            item 
                                            style={webStyle.folderItemGrid}
                                            lg={3}
                                            onClick={() => this.handleOpenFolder(value.name)}
                                        >
                                            <FolderView
                                                iconColor={value.color}
                                                fileCount={value.fileCount}
                                                folderName={value.name}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </>:
                        <InsideFolder
                            folderData = {folderData}
                            data-test-id="insideFolderTestId"
                            handlePageChange={(page)=> this.handleChangePage(page)}
                            backClick={this.handleCloseFolder}
                        />
                    }
                </Box>
            </Drawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainBoxStyle: {
        padding: "24px",
        background: "#fafaf9",
        height: "100%"
    },
    folderItemGrid: {
        flexBasis: "23.5%",
        maxWidth: "25%"
    },
    folderContainerGrid: {
        gridRowGap: "25px",
        marginTop: "20px",
        gridColumnGap: "24px"
    },
    folderText: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#0F172A"
    },
    folderMainBox: {
        marginTop: "25px"
    }
};
// Customizable Area End
