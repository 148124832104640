import React from "react";

// Customizable Area Start
import {Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { logo, mail } from "./assets";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";

// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderResetPassword = () => {
        return (
            <Box style={webStyles.card}>
                <Box style={webStyles.logoContainer}>
                    <img src={logo} alt="Wealth Fusion Logo" style={webStyles.logo} />
                </Box>
                <Typography style={webStyles.title}>{this.resetPasswordText}</Typography>
                <Box style={webStyles.instructionsContainer}>
                    <Typography style={webStyles.instruction}>{this.resetPwdInstrucText}</Typography>
                    <Typography style={webStyles.instruction}>{this.resetPwdSecText}</Typography>
                </Box>
                <Box style={webStyles.formContainer}>
                    <Box style={webStyles.inputContainer}>
                        <Typography variant="body2" color="textSecondary">
                            {this.emailLabelText}
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            defaultValue=""
                            variant="outlined"
                            placeholder={this.enterEmail}
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            data-testid="handleEmailChange"
                            style={webStyles.textField}
                            inputProps={{ style: webStyles.textFieldInput }}
                            InputProps={{
                                style: webStyles.textFieldProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={mail} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                <Box style={webStyles.centered}>
                    <Button
                        data-testid="sentMail"
                        onClick={this.handleSendMailToResetPwd}
                        style={{
                            ...webStyles.button,
                            color: this.state.email ? "white" : "#64748B",
                            backgroundColor: this.state.email ? "blue" :"#E2E8F0",
                        }}
                        disabled={!(this.state.email)}
                    >
                        {this.buttonSendText}
                    </Button>
                </Box>
                <Box style={webStyles.footer}>
                    <Typography variant="body2" color="textSecondary">
                        Back to&nbsp;
                    </Typography>
                    <Typography
                    variant="body2"
                        data-testid="redirectToLogin"
                        onClick={this.redirectToLogin}
                        style={webStyles.link}
                    >
                        Log in
                    </Typography>
                </Box>
            </Box>
        )
    }

    renderSentMail = () => {
        return (
            <Box style={webStyles.sentMailContainer}>
                <Box style={webStyles.logoContainer}>
                    <img src={logo} alt="Wealth Fusion Logo" style={webStyles.logo} />
                </Box>
                <Box style={webStyles.textCenter}>
                    <Typography style={webStyles.createNewPwdText}>{this.resetPasswordText}</Typography>
                </Box>
                <Box style={webStyles.textCenter}>
                    <Typography style={webStyles.createPwdText}>{this.sentMailToText + " " + "John@gmail.com"}</Typography>
                </Box>
                <Box mx={'25px'} style={webStyles.resendMailContainer}>
                    <Typography
                        data-testid="redirectToLogin"
                        onClick={this.handleSendMailToResetPwd}
                        variant="body2"
                        color="blue"
                        style={webStyles.resendLink}
                    >
                        Resend e-mail
                    </Typography>
                    <Box style={webStyles.redirectContainer}>
                    <Typography variant="body2" color="textSecondary">
                        Back to&nbsp;
                    </Typography>
                    <Typography
                        data-testid="redirectToLogin"
                        onClick={this.redirectToLogin}
                        variant="body2"
                        color="blue"
                        style={webStyles.redirectText}
                    >
                        Log in
                    </Typography>
                </Box>
                </Box>
            </Box>
        )
    }



    // Customizable Area End
    render() {
        // Customizable Area Start
        const { isSentEmail } = this.state;
        // Customizable Area End



        return (
            // Customizable Area Start
            // Required for all blocks
            <>
            <Box style={webStyles.boxMainContainer}>
                {!isSentEmail ? this.renderResetPassword() : this.renderSentMail()}
            </Box>
            <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={this.state.errorState}
                        onClose={this.handleCloseError}
                        autoHideDuration={2000}
                    >
                        <Alert
                            onClose={this.handleCloseError}
                            icon={false}
                            variant="filled"
                            severity={this.state.toastType}
                            sx={{ width: '100%', fontWeight: 400, fontSize: '18px' }}
                            // style={webStyles.errorBox}
                        >
                            {this.state.formError}
                        </Alert>
                    </Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyles: Record<string, React.CSSProperties> = {
    boxMainContainer: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%",
        height: "100%",
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    card: {
        height: "485px",
        width: "458px",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "24px",
        marginTop: "30px",
    },
    logo: {
        height: "77px",
        width: "208px",
    },
    title: {
        fontWeight: 400,
        fontSize: "18px",
        textAlign: "center",
    },
    instructionsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    instruction: {
        fontWeight: 400,
        fontSize: "13px",
        color: "#64748B",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "20px 25px",
    },
    errorBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "red",
        backgroundColor: "#fdeded",
        borderRadius: "5px",
        borderLeft: "4px solid red",
    },
    errorIcon: {
        cursor: "pointer",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
    },
    textField: {
        width: "100%",
    },
    textFieldProps: {
        borderRadius: "50px",
        height: "35px",
    },
    centered: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        width: "90%",
        borderRadius: "30px",
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "capitalize",
        cursor: "pointer",
    },
    inactiveButton: {
        color: "#64748B",
        backgroundColor: "#E2E8F0",
    },
    footer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
    },
    link: {
        color: "blue",
        cursor: "pointer",
    },
    iconButton: {
        cursor: "pointer",
    },
    sentMailContainer: {
        height: "fit-content",
        width: "458px",
        padding: "1rem",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
    },
    textCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    createNewPwdText: {
        fontWeight: 400,
        fontSize: "18px",
    },
    createPwdText: {
        fontWeight: 400,
        fontSize: "13px",
        color: "#64748B",
        textAlign: "center" as const,
    },
    resendMailContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "24px",
        alignItems: "center",
    },
    resendLink: {
        cursor: "pointer",
    },
    loginLink: {
        cursor: "pointer",
        color: "blue",
    },
    createContainer: {
        height: "fit-content",
        width: "458px",
        padding: "1rem",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
    },
    textCenterColumn: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
    resetPasswordText: {
        fontWeight: 400,
        fontSize: "18px",
    },
    resetButton: {
        width: "90%",
        borderRadius: "30px",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize" as const,
        cursor: "pointer",
    },
    redirectContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
    },
    successContainer: {
        height: "fit-content",
        width: "450px",
        padding: "1rem",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
    },
    successImage: {
        height: "120px",
        width: "150px",
    },
    textCenterPadding: {
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center",
    },
    textCenterColumnPadding: {
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
    pwdChangedSuccessfully: {
        fontWeight: 400,
        fontSize: "18px",
    },
    successPwdText: {
        fontWeight: 400,
        fontSize: "13px",
        color: "#64748B",
    },
    successButton: {
        width: "90%",
        borderRadius: "30px",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize" as const,
        cursor: "pointer",
        color: "white",
        backgroundColor: "blue",
    },
    logoImage: {
        height: "77px",
        width: "208px",
    },
    errorContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0.5rem 0",
        padding: "10px 15px",
        color: "red",
        backgroundColor: "#FEE2E2",
        borderRadius: "5px",
        borderLeft: "4px solid red",
    },
    textFieldInputProps: {
        borderRadius: "50px",
        height: "35px",
    },
    redirectText: {
        cursor: "pointer",
    },
};


// Customizable Area End