import React, { Component } from "react";
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    styled,
    Pagination,
    PaginationProps,
    Menu,
    IconButton
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileView from "./FileView";

interface FolderFile {
    file: {
        name: string;
        size: string
    };
    uploadDate: string;
    lastUpdate: string;
    uploadBy: string
};

interface StyledPaginationProps extends PaginationProps {
    totalPages: number;
}

interface Props {
    folderData: {
        folderName: string;
        folderFiles: FolderFile[];
        currentPage: number;
        totalPages: number;
    };
    backClick: ()=>void;
    handlePageChange: (page: number)=>void;
};

interface S {
    anchorEl: HTMLElement | null;
};

interface SS {
};

export default class InsideFolder extends Component<Props, S, SS> {

    constructor(props: Props){
        super(props);
        this.state = {
            anchorEl: null
        }
    };

    handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.props.handlePageChange(page);
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    renderPaginationRow = () => {
        const { folderData } = this.props;
        return (
            <TableRow>
                <TableCell colSpan={5}>
                    <Box style={webStyle.paginationMainBox}>
                        <Typography style={webStyle.pageText}>
                            Page {folderData.currentPage} of {folderData.totalPages }
                        </Typography>
                        <StyledPagination
                            data-test-id="paginationTestId"
                            page={folderData.currentPage}
                            count={folderData.totalPages}
                            variant="outlined"
                            shape="rounded"
                            totalPages={folderData.totalPages}
                            onChange={this.handlePageChange}
                        />
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    renderMenu = () => {
        const { anchorEl } = this.state
        return (
            <Menu
                open={Boolean(anchorEl)}
                id="basic-menu"
                data-test-id="publishMenuTestID"
                anchorEl={anchorEl}
                onClose={this.handleMenuClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal:'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                slotProps={{
                    paper: {
                        style: {
                            overflow: 'visible',
                            boxShadow: '0px 0px 8px 4px #00000008',
                            borderRadius: "16px",
                            border: "1px solid #CBD5E1"
                        }
                    }
                }}
            >
                <Box style={webStyle.menuBox}>
                    <MenuItemBox>
                        <FileDownloadOutlinedIcon/>
                        <Typography>
                            Download
                        </Typography>
                    </MenuItemBox>
                    <MenuItemBox>
                        <EditOutlinedIcon/>
                        <Typography>
                            Manage
                        </Typography>
                    </MenuItemBox>
                    <MenuItemBox>
                        <DeleteOutlineRoundedIcon style={{color: "#DC2626"}}/>
                        <Typography style={{color: "#DC2626"}}>
                            Delete
                        </Typography>
                </MenuItemBox>
                </Box>
            </Menu>
        )
    };

    render() {
        const { folderData, backClick } = this.props;
        return (
            <Box>
                <Box style={webStyle.headingBox}>
                    <ArrowBackIcon
                        data-test-id="backArrowButtonTestId"
                        style={webStyle.backArrow}
                        onClick={backClick}
                    />
                    <Typography style={webStyle.headingText}>{folderData.folderName}</Typography>
                </Box>
                <Box>
                    <StyledTableContainer>
                        <Table>
                            <StyledTableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box style={webStyle.nameHeadingCell}>
                                            {
                                                <Box style={webStyle.checkBoxNotSelect}>
                                                </Box>
                                            }
                                            Name
                                        </Box>
                                    </TableCell>
                                    <TableCell>Date uploaded</TableCell>
                                    <TableCell>Last updated</TableCell>
                                    <TableCell>Uploaded by</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </StyledTableHead>
                            <StyledTableBody>
                                {folderData.folderFiles.map((item, index) => (
                                    <TableRow key={index}>
                                    <TableCell>
                                        <Box style={webStyle.tableFileBox}>
                                            <Box style={webStyle.checkBoxNotSelect}>
                                            </Box>
                                            <FileView name={item.file.name} size={item.file.size}/>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{item.uploadDate}</TableCell>
                                    <TableCell>{item.lastUpdate}</TableCell>
                                    <TableCell>{item.uploadBy}</TableCell>
                                    <TableCell style={{width: "28px"}}>
                                        <IconButton
                                            onClick={this.handleMenuOpen}
                                        >
                                            <MoreVertIcon style={webStyle.moreVertIcon}/>
                                        </IconButton>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                {this.renderPaginationRow()}
                            </StyledTableBody>
                        </Table>
                    </StyledTableContainer>
                </Box>
                {this.renderMenu()}
            </Box>
        );
    }
};

const StyledTableContainer = styled(TableContainer)({
    border: "1px solid #E2E8F0",
    borderRadius: "25px",
    overflow: "hidden",
    background: "white"
});
  
const StyledTableHead = styled(TableHead)({
    "& .MuiTableRow-root": {
      backgroundColor: "#F8FAFC"
    },
    "& .MuiTableCell-root": {
      borderRight: "1px solid #E2E8F0",
      borderBottom: "1px solid #E2E8F0",
      color: "#64748B",
      fontSize: "12px",
      fontWeight: 500
    },
    "& .MuiTableCell-root:last-child": {
      borderRight: "none"
    }
});

const StyledTableBody = styled(TableBody)({
    "& .MuiTableCell-root": {
        borderRight: "1px solid #E2E8F0",
        borderBottom: "1px solid #E2E8F0",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 500
    },
    "& .MuiTableCell-root:last-child": {
      borderRight: "none"
    }
});

const StyledPagination = styled(Pagination)<StyledPaginationProps>(({ totalPages }) => ({
    "& .MuiPaginationItem-root": {
        margin: "0",
        border: "1px solid #E5E5E5",
        borderRadius: "0px",
        height: "32px",
        boxShadow: "0px 4px 8px -5px #00000026",
        color: "#0F172A",
        borderRight: "1px solid #E2E8F0",
        borderLeft: "none",
        fontWeight: 600,
        fontSize: "12px"
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
        marginLeft: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
        marginRight: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label$='page 1']": {
        borderRadius: "8px 0 0 8px",
        borderLeft: "1px solid #E5E5E5"
    },
    [`& .MuiPaginationItem-root[aria-label$='page ${totalPages}']`]: {
        borderRadius: "0 8px 8px 0",
        borderRight: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root:hover": {
        background: "#E2E8F0"
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        background: "#E2E8F0"
    }
}));

const MenuItemBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    color: "#0F172A",
    gap: "8px",
    width: "150px",
    padding: "12px 16px",
    borderRadius: "8px",
    border: "1px solid transparent",
    cursor: "pointer",
    "&:hover": {
        background: "#F8FAFC",
        borderColor: "#CBD5E1"
    },
    "& .MuiSvgIcon-root": {
        fontSize: "20px"
    },
    "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 600,
        color: "#0F172A",
        marginTop: "5px"
    }
});

const webStyle = {
    headingBox: {
        display: "flex",
        gap: "20px",
        marginBottom: "24px"
    },
    backArrow: {
        fontSize: "30px",
        color: "#0F172A"
    },
    headingText: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#0F172A"
    },
    moreVertIcon: {
        fontSize: "20px",
        color: "#475569",
        cursor: "pointer"
    },
    checkBoxNotSelect: {
        border: "1px solid #CBD5E1",
        background: "#FFFFFF",
        borderRadius: "6px",
        width: "20px",
        height: "20px",
        overflow: "hidden",
        cursor: "pointer"
    },
    nameHeadingCell: {
        display: "flex",
        gap: "15px"
    },
    checkBoxIconStyle: {
        marginTop: "-4px",
        marginLeft: "-4px",
        fontSize: "28px",
        color: "#3B82F6"
    },
    tableFileBox: {
        display: "flex",
        gap: "15px",
        alignItems: "center"
    },
    paginationMainBox: {
        background: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    pageText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 500
    },
    menuBox: {
        margin: "5px 15px"
    }
};