import React from "react";

//Customizable Area Start
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import NewPasswordController, {
    Props,
} from "./NewPasswordController.web";
import { logo, password, successImage } from "./assets";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
//Customizable Area End

export default class NewPassword extends NewPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }
    //Customizable Area Start

    handleRenderPassword = () => {
        return !this.state.showPassword ? <VisibilityOutlinedIcon onClick={this.togglePasswordVisibility} data-testid='togglePasswordVisibility' style={{ cursor: 'pointer' }} /> : <VisibilityOffOutlinedIcon style={{ cursor: 'pointer' }} onClick={this.togglePasswordVisibility} />
    }
    handleRenderConfirmPassword = () => {
        return !this.state.showConfirmPassword ? <VisibilityOutlinedIcon onClick={this.toggleConfirmPasswordVisibility} data-testid='toggleConfirmPasswordVisibility' style={{ cursor: 'pointer' }} /> : <VisibilityOffOutlinedIcon style={{ cursor: 'pointer' }} onClick={this.toggleConfirmPasswordVisibility} />
    }

    renderCreateNewPassword = () => {
        const { newPassword, confirmNewPassword } = this.state;
        return (
            <Box style={webStyles.createContainer}>
                <Box style={webStyles.logoContainer}>
                    <img src={logo} alt="Wealth Fusion Logo" style={webStyles.logoImage} />
                </Box>
                <Box style={webStyles.textCenter}>
                    <Typography style={webStyles.resetPasswordText}>
                        {this.createNewPwd}
                    </Typography>
                </Box>
                <Box style={webStyles.textCenterColumn}>
                    <Typography align="center" style={webStyles.createPwdText}>
                        {this.createPwdText}
                    </Typography>
                </Box>
                <Box style={webStyles.formContainer}>
                    <Box style={webStyles.inputContainer}>
                        <Typography variant="body2" color="textSecondary">
                            {this.newPasswordLabelText}
                        </Typography>
                        <TextField
                            required
                            id="new-password"
                            type={!this.state.showPassword ? "text" : "password"}
                            variant="outlined"
                            placeholder={this.enterNewPassword}
                            value={newPassword}
                            onChange={this.handleNewPasswordChange}
                            error={!!this.state.newPasswordError}
                            helperText={this.state.newPasswordError}
                            inputProps={{ style: webStyles.textFieldInput }}
                            InputProps={{
                                style: webStyles.textFieldInputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={password} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" aria-label="toggle password visibility">
                                            {this.handleRenderPassword()}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box style={webStyles.inputContainer}>
                        <Typography variant="body2" color="textSecondary">
                            {this.confirmNewPasswordLabelText}
                        </Typography>
                        <TextField
                            required
                            id="confirm-new-password"
                            type={!this.state.showConfirmPassword ? "text" : "password"}
                            variant="outlined"
                            placeholder={this.enterConfirmNewPassword}
                            value={confirmNewPassword}
                            onChange={this.handleConfirmNewPasswordChange}
                            error={!!this.state.confirmNewPasswordError}
                            helperText={this.state.confirmNewPasswordError}
                            inputProps={{ style: webStyles.textFieldInput }}
                            InputProps={{
                                style: webStyles.textFieldInputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={password} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" aria-label="toggle password visibility">
                                            {this.handleRenderConfirmPassword()}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                <Box style={webStyles.textCenter}>
                    <Button
                        data-testid="resetPasswordButton"
                        onClick={this.handleSubmit}
                        style={{
                            ...webStyles.resetButton,
                            color: newPassword && confirmNewPassword ? "white" : "#64748B",
                            backgroundColor: newPassword && confirmNewPassword ? "blue" : "#E2E8F0",
                        }}
                        disabled={!(newPassword && confirmNewPassword)}
                    >
                        {this.resetPasswordButton}
                    </Button>
                </Box>
                <Box style={webStyles.redirectContainer}>
                    <Typography variant="body2" color="textSecondary">
                        Back to&nbsp;
                    </Typography>
                    <Typography
                        data-testid="redirectToLogin"
                        onClick={this.redirectToLogin}
                        variant="body2"
                        color="blue"
                        style={webStyles.redirectText}
                    >
                        Log in
                    </Typography>
                </Box>
            </Box>
        )
    }

    renderForgotPwdSuccess = () => {
        return (
            <Box style={webStyles.successContainer}>
                <Box style={webStyles.logoContainer}>
                    <img src={successImage} alt="Wealth Fusion Logo" style={webStyles.successImage} />
                </Box>
                <Box style={webStyles.textCenterPadding}>
                    <Typography style={webStyles.pwdChangedSuccessfully}>You are successfully changed your password</Typography>
                </Box>
                <Box style={webStyles.textCenterColumnPadding}>
                    <Typography align="center" style={webStyles.successPwdText}>
                        {this.successPwdText}
                    </Typography>
                </Box>
                <Box style={webStyles.textCenterPadding}>
                    <Button
                        data-testid="redirectToLogin"
                        onClick={this.redirectToLogin}
                        style={webStyles.successButton}
                    >
                        {this.backToLogin}
                    </Button>
                </Box>
            </Box>
        )
    }
    //Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                <Box style={webStyles.boxMainContainer_}>
                    {!this.state.isResetPwd ? this.renderCreateNewPassword() : this.renderForgotPwdSuccess()}
                </Box>
                <Snackbar
                    autoHideDuration={2000}
                    open={this.state.errorState}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={this.handleCloseError}
                >
                    <Alert
                        severity={this.state.toastType}
                        onClose={this.handleCloseError}
                        variant="filled"
                        icon={false}
                        sx={{ width: '100%', fontWeight: 400, fontSize: '18px' }}
                    >
                        {this.state.formError}
                    </Alert>
                </Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles: Record<string, React.CSSProperties> = {
    boxMainContainer_: {
        alignItems: 'center',
        display: "flex",
        height: "100%",
        justifyContent: 'center',
        width: "100%",
    },
    button: {
        borderRadius: "30px",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "capitalize",
        width: "90%",
    },
    card: {
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
        height: "485px",
        width: "458px",
    },
    centered: {
        display: "flex",
        justifyContent: "center",
    },
    container: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
    },
    createContainer: {
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
        height: "fit-content",
        padding: "1rem",
        width: "458px",
    },
    createNewPwdText: {
        fontSize: "18px",
        fontWeight: 400,
    },
    createPwdText: {
        color: "#64748B",
        fontSize: "13px",
        fontWeight: 400,
        textAlign: "center" as const,
    },
    errorBox: {
        alignItems: "center",
        backgroundColor: "#FEE2E2",
        borderLeft: "4px solid red",
        borderRadius: "5px",
        color: "red",
        display: "flex",
        justifyContent: "space-between",
        margin: "0.5rem 0",
        padding: "10px 15px",
    },
    errorContainer: {
        alignItems: "center",
        backgroundColor: "#FEE2E2",
        borderLeft: "4px solid red",
        borderRadius: "5px",
        color: "red",
        display: "flex",
        justifyContent: "space-between",
        margin: "0.5rem 0",
        padding: "10px 15px",
    },
    errorIcon: {
        cursor: "pointer",
    },
    footer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "20px 25px",
    },
    inactiveButton: {
        backgroundColor: "#E2E8F0",
        color: "#64748B",
    },
    iconButton: {
        cursor: "pointer",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
    },
    instruction: {
        color: "#64748B",
        fontSize: "13px",
        fontWeight: 400,
    },
    instructionsContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    link: {
        color: "blue",
        cursor: "pointer",
    },
    loginLink: {
        color: "blue",
        cursor: "pointer",
    },
    logo: {
        height: "77px",
        width: "208px",
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "24px",
        marginTop: "30px",
    },
    logoImage: {
        height: "77px",
        width: "208px",
    },
    pwdChangedSuccessfully: {
        fontSize: "18px",
        fontWeight: 400,
    },
    redirectContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
    },
    redirectText: {
        cursor: "pointer",
    },
    resendLink: {
        cursor: "pointer",
    },
    resendMailContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "24px",
    },
    resetButton: {
        borderRadius: "30px",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize" as const,
        width: "90%",
    },
    resetPasswordText: {
        fontSize: "18px",
        fontWeight: 400,
    },
    sentMailContainer: {
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
        height: "fit-content",
        padding: "1rem",
        width: "458px",
    },
    successButton: {
        backgroundColor: "blue",
        borderRadius: "30px",
        color: "white",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize" as const,
        width: "90%",
    },
    successContainer: {
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
        height: "fit-content",
        padding: "1rem",
        width: "450px",
    },
    successImage: {
        height: "120px",
        width: "150px",
    },
    successPwdText: {
        color: "#64748B",
        fontSize: "13px",
        fontWeight: 400,
    },
    textCenter: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    textCenterColumn: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    textCenterColumnPadding: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0.5rem",
    },
    textCenterPadding: {
        display: "flex",
        justifyContent: "center",
        padding: "0.5rem",
    },
    textField: {
        width: "100%",
    },
    textFieldInputProps: {
        borderRadius: "50px",
        height: "35px",
    },
    textFieldProps: {
        borderRadius: "50px",
        height: "35px",
    },
    title: {
        fontSize: "18px",
        fontWeight: 400,
        textAlign: "center",
    },
};
// Customizable Area End