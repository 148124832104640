// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

// Customizable Area Start
interface S {
  showPassword: boolean;
  email: string;
  isChecked: boolean;
  showConfirmPassword: boolean;
  isSentEmail: boolean;
  formError: string;
  confirmNewPassword: string;
  newPassword: string;
  newPasswordError: string,
  isPasswordResetError: string;
  errorState: boolean,
  toastType: "error" | "success",
  confirmNewPasswordError: string,
}
// Customizable Area End

interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resetPwdSecText: string = configJSON.resetPasswrodSecondaryText;
  resetPasswordText: string = configJSON.resetPasswordText
  emailLabelText: string = configJSON.firstInputPlaceholder;
  buttonSendText: string = configJSON.sendButtonText;
  enterNewPassword: string = configJSON.enterNewPassword;
  resetPasswordButton: string = configJSON.resetPasswordButton;
  sentMailToText: string = configJSON.sentMailToText;
  enterEmail: string = configJSON.enterEmalplaceholderText;
  createPwdText: string = configJSON.createPwdText;
  createNewPwd: string = configJSON.createNewPwd;
  resetPwdInstrucText: string = configJSON.resetPasswordInstructionText;
  emailInvalidText: string = configJSON.invalidEmailAddress;
  newPasswordLabelText: string = configJSON.newPasswordLabelText;
  confirmNewPasswordLabelText: string = configJSON.confirmNewPasswordLabelText;
  enterConfirmNewPassword: string = configJSON.enterConfirmNewPassword;
  successPwdText: string = configJSON.successPwdText;
  apiResetPassowrdCallId = '';
  backToLogin: string = configJSON.backToLogin;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      showPassword: true,
      email: "",
      showConfirmPassword: true,
      toastType: "success",
      formError: "",
      isSentEmail: false,
      confirmNewPassword: '',
      isChecked: false,
      errorState: false,
      isPasswordResetError: '',
      confirmNewPasswordError: "",
      newPassword: '',
      newPasswordError: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };

  validateEmail = (email: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return configJSON.emailRegex.test(email);
  };

  handleSendMailToResetPwd = () => {
    const { email } = this.state;

    if (this.validateEmail(email)) {
      this.resetPasswordSentLinkToEmail();
    }
  };

  resetPasswordSentLinkToEmail = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const attrs = {
      email: this.state.email,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBodyData = {
      data: data,
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPassowrdCallId = requestMessages.messageId;

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_forgot_password/forgot_passwords"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodyData)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleSentMailApiResponse(apiRequestCallId, responseJson)
    }
  }

  handleSentMailApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiResetPassowrdCallId) {
      if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0] &&
        responseJson.errors[0].token === 'Account not found'
      ) {
        this.setState({
          errorState: true,
          toastType: "error",
          formError: responseJson.errors[0].token,
        });
      }
      else if (responseJson && responseJson.message === "Password Reset link sent Successfully") {
        this.setState({
          errorState: true,
          toastType: "success",
          isSentEmail: true,
          formError: responseJson.message,
        });
      }
    }
  };


  redirectToLogin = () => {
    this.props.navigation.navigate("Home");
  };

  redirectToForgotPassword = () => {
    this.props.navigation.navigate("ForgotPassword");
  };

  handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ errorState: false });
  };
  // Customizable Area End
}
