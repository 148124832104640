import React from "react";

// Customizable Area Start
import {
    Box,
    Typography
} from "@mui/material";
import { file, files, home, pdf, people, star, logo } from './assets';
import Headers from "../../../components/src/Headers";
// Customizable Area End

import DrawerController, {
    Props,
    configJSON,
} from "./DrawerController";

// Customizable Area Start
// Customizable Area End

export default class Drawer extends DrawerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { pageName } = this.props;
        const { hoverOn } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyle.mainBox}>
                <Box style={webStyle.sideDrawerMainBox}>
                    <img src={logo} alt="Wealthfusion_logo" style={{ width: "124px"}}/>
                    <Box py={"20px"}>
                        <p style={{ color: '#0F172A' }}>{configJSON.mainMenu}</p>
                        <Box>
                            {[
                                {label: "Home", icon: home, route: "Dashboard"},
                                {label: "Clients", icon: people, route: "Clients"},
                                {label: "Forms", icon: file, route: "Forms"},
                                {label: "Products", icon: star, route: "Products"},
                                {label: "Reports", icon: files, route: "Reports"},
                                {label: "Policies", icon: pdf, route: "Policies"}
                            ].map((item, index)=>
                                <Typography
                                    data-test-id={`navButton${index}TestId`}
                                    key={index}
                                    style={(hoverOn === item.label || pageName === item.route)? {...webStyle.menuContainer, ...webStyle.hovertab}: webStyle.menuContainer}
                                    onClick={()=> this.handleNavigation(item.route)}
                                    onMouseEnter={()=> this.handleOnHoverEnter(item.label)}
                                    onMouseLeave={()=> this.handleOnHoverLeave()}
                                >
                                    <span style={webStyle.spanContainerMenu}>
                                        {(index === 0 || index === 1 || index === 3 )?
                                            React.cloneElement(item.icon, { style: (hoverOn === item.label || pageName === item.route)? {...webStyle.svgStyle, fill: "#6200EA"} : webStyle.svgStyle }):
                                            React.cloneElement(item.icon, { style: (hoverOn === item.label || pageName === item.route)? {...webStyle.svgStyle, stroke: "#6200EA"} : {...webStyle.svgStyle, stroke: "#64748B"} })
                                        }
                                    </span>
                                    <p>{item.label}</p>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box style={{width: "100%"}}>
                    <Headers />
                    {this.props.children}
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainBox: {
        display: "flex",
        flexDirection: "row" 
    } as React.CSSProperties,
    sideDrawerMainBox: {
        padding: "20px",
        borderRight: '1px solid #E2E8F0',
        width: '100%',
        maxWidth: "250px"
    },
    hovertab: {
        fontWeight: 600,
        border: '1px solid #CBD5E1',
        background: '#F8FAFC',
        color: "#6200EA",     
        boxShadow: "0px 4px 8px -5px #00000026"
    },
    menuContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '5px',
        width: '230px',
        height: '40px',
        borderRadius: '8px',
        boxSizing: "border-box",
        fontWeight: 500,
        border: "1px solid transparent",
        color: "#64748B"
    } as React.CSSProperties,
    spanContainerMenu: {
        padding: '12px 12px 18px 10px'
    },
    svgStyle: {
        fill: "#64748B"
    }
};
// Customizable Area End